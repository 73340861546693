import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { rhythm } from "typography";
import { FaCaretLeft, FaCaretRight, FaTimes } from "react-icons/fa";

const ModalBackground = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  margin: auto;
  width: 80%;
  height: 80%;

  > * {
    width: 100%;
    height: auto;
  }
`;

const NavButton = styled.div`
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  user-select: none;
`;

const CloseButton = styled(NavButton)`
  position: absolute;
  top: 100px;
  right: 100px;
`;

const Modal = ({ children }) => {
  return (
    <ModalBackground
      onClick={() => navigate(`/`, { state: { noScroll: true } })}
    >
      <ModalContent>
        {/* <NavButton as={FaCaretLeft} size="50px" /> */}
        {children}
        {/* <NavButton as={FaCaretRight} size="50px" /> */}
      </ModalContent>

      {/* <CloseButton
        as={FaTimes}
        size="30px"
        onClick={() => navigate(`/`, { state: { noScroll: true } })}
      /> */}
    </ModalBackground>
  );
};

export default Modal;
