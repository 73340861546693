import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Modal from "../components/modal/modal";

const Screenshot = ({ data }) => {
  return (
    <Modal>
      <Img fluid={data.file.childImageSharp.fluid} />
    </Modal>
  );
};

export default Screenshot;

export const pageQuery = graphql`
  query ScreenShotByRelativePath($relativePath: String!) {
    file(relativePath: { eq: $relativePath }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
